import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet/Helmet";
import SearchSelectRaces from "./components/SearchSelectRaces";
import MobileApps from "./components/MobileApps";

const Home = () => {
  const { t } = useTranslation();
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  return (
    <>
      <HelmetComponent
        title={t("VR-RACES") || undefined}
        descrition={t("SEO2 Home description") || undefined}
      />
      <SearchSelectRaces />
      <MobileApps />
    </>
  );
};

export default Home;
