import { useState } from "react";

export default function useModal() {
  const [isOpen, setisOpen] = useState(false);
  const [isOpenToggleSuccess, setisOpenToggleSuccess] = useState(false);

  const toggle = () => {
    setisOpen(!isOpen);
  };
  const toggleSuccess = () => {
    setisOpenToggleSuccess(!isOpenToggleSuccess);
  };

  return {
    isOpen,
    toggle,
    isOpenToggleSuccess,
    toggleSuccess
  };
}
