import { Row, Col } from 'antd';
import classes from './Footer.module.css';
import { useTranslation } from "react-i18next";
import pdm from '../../img/Logo_dpm_footer.png';
import prog_anapt from '../../img/prog_anapt.png';
import dotsoft from '../../img/dotsoft.png';
import youtube from '../../img/youtube-icon.png';
import twitter from '../../img/twitter-icon.png';
import facebook from '../../img/facebook-icon.png';

const Footer = () => {

    const { t } = useTranslation()

    return (
        <>
            <div className={classes.footerContainer}>
                <Row justify="center">
                    <Col xs={{ span: 22 }} sm={{ span: 20 }} md={{ span: 14 }} lg={{ span: 20 }}>
                        <Row justify="center">
                            <Col xs={24} sm={24} md={8} lg={6} className={classes.footer_col} >
                                <div className={classes.iconsFooter}>
                                    <a href="https://pavlosmelas.gr/" target="_blank" rel="noopener noreferrer"><img src={pdm} className={classes.icon} alt="external-link" /></a>
                                    {/* <a href="#"><img src={facebook} className={classes.icon} alt="external-link" /></a> */}
                                </div>

                            </Col>
                            <Col xs={24} sm={24} md={8} lg={6} className={classes.footer_col}>
                                <div className={classes.TitleFooter}>{t('Δήμος Παύλου Μελά')}</div>
                                <div className={classes.TitleFooter2}>{t('τηλ: ')}<a href="tel:+302313302800" style={{ color: '#4080a0' }}>2313 302800</a> {t('Fax: ')}<a href="tel:+302310656040" style={{ color: '#4080a0' }}>2310 656040</a></div>

                                <a href="#"><img src={youtube} className={classes.social_icon } alt="external-link" /></a>
                                <a href="#"><img src={twitter} className={classes.social_icon } alt="external-link" /></a>
                                <a href="#"><img src={facebook} className={classes.social_icon } alt="external-link" /></a>

                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} className={classes.footer_col}>
                                <div className={classes.iconsFooter}>
                                    <a href="#"><img src={prog_anapt} className={classes.icon} alt="external-link" /></a>
                                    {/* <a href="#"><img src={facebook} className={classes.icon} alt="external-link" /></a> */}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} className={classes.footer_col}>
                                <div className={classes.TitleFooter}>{t('Developed by')}</div>
                                <div>
                                    <a href="https://dotsoft.gr" target="_blank" rel="noopener noreferrer"><img src={dotsoft} className={classes.icon} alt="external-link" /></a>
                                    {/* <a href="#"><img src={facebook} className={classes.icon} alt="external-link" /></a> */}
                                </div>
                            </Col>
                        </Row>
                        
                        <br /> <br /><br />
                        
                        <div style={{ color: '#4080a0' }}>
                            <p className={classes.copyrights}>Copyright 2023 © ΔΗΜΟΣ ΠΑΥΛΟΥ ΜΕΛΑ <br />
                            <a style={{ color: '#4080a0' }} href="/terms-of-use" target="_blank" rel="noopener noreferrer">Privacy Policy - Terms of Use</a></p>
                        </div>

                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Footer