import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Select, Table } from "antd";
import type { TablePaginationConfig } from "antd/es/table";
import type { FilterValue } from "antd/es/table/interface";
import { useParams } from "react-router-dom";
import HelmetComponent from "../../components/Helmet/Helmet";
import { UserAuthContext } from "../../context/UserAuthContext";
import { UserAuthType } from "../../types/UserAuth";
import parse from "html-react-parser";
import moment from "moment";
import axios from "axios";
import classes from "./SingleResult.module.css";
const { Option } = Select;

interface DataType {
  position: number;
  completion_time: number;
  name: string;
  surname: string;
  user_id: number;
  residence: string;
  gender: string;
  age: number;
  valid: number;
  has_completed_race: number;
}

interface ResponseType {
  id: number;
  user_id: number;
  completion_time: any;
  average_speed: number;
  distance: string;
  status: string;
  created_at: string;
  updated_at: string;
  race_id: number;
  gpx_path: string;
  valid: number;
  has_completed_race: number;
  user: {
    id: number;
    name: string;
    email: string;
    email_verified_at: string | null;
    created_at: string;
    updated_at: string;
    surname: string;
    gender: string;
    date_of_birth: string;
    place_of_birth: string;
    residence: string;
    club: string;
    ITRA: string | null;
    approves_result_sharing: number;
    media: any[];
  };
}

interface Range {
  id: number;
  value: string;
  label: string;
  name: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const Results = () => {
  const params = useParams();
  const [raceData, setRaceData] = useState<any>();
  const [data, setData] = useState<DataType[]>([]); // Update to use state
  const [selectedGender, setSelectedGender] = useState<string>("");
  const [selectedAgeRange, setSelectedAgeRange] = useState<number>(0);
  const [originalData, setOriginalData] = useState<DataType[]>([]);
  const [ranges, setRanges] = useState<Range[]>([]);
  const [raceList, setRaceList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  // const [tableParams, setTableParams] = useState<TableParams>({
  //     pagination: {
  //         current: 1,
  //         pageSize: 10,
  //     },
  // });
  const columns = [
    {
      title: "Θέση",
      dataIndex: "position",
      key: "position",
      width: 80,
    },
    {
      title: "Χρόνος",
      dataIndex: "completion_time",
      key: "completion_time",
      render: (text: string, record: DataType) =>
        formatCompletionTime(record.completion_time),
      width: 110,
    },
    {
      title: "Νο.",
      dataIndex: "user_id",
      key: "user_id",
      width: 60,
    },
    {
      title: "Ηλικία",
      dataIndex: "age",
      key: "age",
      width: 80,
    },
    {
      title: "Φύλο",
      dataIndex: "gender",
      key: "gender",
      width: 80,
    },
    {
      title: "Ονοματεπώνυμο",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: DataType) =>
        `${record.name} ${record.surname}`,
      width: 320,
    },
    {
      title: "Περιοχή",
      dataIndex: "residence",
      key: "residence",
      width: 140,
    },
  ];
  data.forEach((item, index) => {
    item.position = index + 1;
  });
  
  
  const formatCompletionTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    return formattedTime;
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://admin-vrraces.pavlosmelas.gr/api/race/results?race_id=${params.id}`
        );
        const checkedData = response.data.filter((item:any)=>(item.valid === 1 && item.has_completed_race === 1));
  console.log(data);
        console.log(checkedData);
        
        const filteredData = checkedData.map((item: any, index: number) => ({
        key: index,
        position: index + 1,
        completion_time: item.completion_time,
        name: item.user?.name ?? "",
        surname: item.user?.surname ?? "",
        user_id: item.user_id,
        residence: item.user?.residence ?? "",
        gender: item.user && item.user.gender ? item.user.gender : "",
        age: calculateAge(item.user?.date_of_birth),
        valid: item.user?.valid ?? 0,
        has_completed_race: item.user?.has_completed_race ?? 0,
      }));
      
      console.log(filteredData.data);
      

      setOriginalData(filteredData);
      setData(filteredData);
    } catch (error) {
      console.error(error);
    }
  };

  const calculateAge = (dateOfBirth: string) => {
    const age = moment().diff(dateOfBirth, "years");
    return age;
  };
  const getRaces = async () => {
    try {
      const url = "https://admin-vrraces.pavlosmelas.gr/api/races";
      const response = await axios.get(url);

      const currentDate = new Date();

      const filteredRaces = response.data.filter((race: any) => {
        const raceDateParts = race.end_date.split(" ");
        const raceDate = raceDateParts[0].split("/"); // assuming date format: dd/mm/yyyy
        const raceTime = raceDateParts[1].split(":"); // assuming time format: hh:mm:ss
        const year = parseInt(raceDate[2]);
        const month = parseInt(raceDate[1]) - 1; // month is 0-based in JavaScript
        const day = parseInt(raceDate[0]);
        const hour = parseInt(raceTime[0]);
        const minute = parseInt(raceTime[1]);
        const second = parseInt(raceTime[2]);

        const raceEndDate = new Date(year, month, day, hour, minute, second);

        return raceEndDate < currentDate;
      });

      setRaceList(filteredRaces);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching races:", error);
    }
  };
  const getRanges = async () => {
    try {
      const response = await axios.get(
        "https://admin-vrraces.pavlosmelas.gr/api/age-ranges",
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );

      const ranges = response.data.map((range: any) => ({
        id: range.id,
        value: range.value,
        label: range.label, // Add the 'label' property
        name: range.name,
      }));

      setRanges(ranges);
    } catch (error) {
      console.error("Error fetching age ranges:", error);
    }
  };

  const { t } = useTranslation();
  const { userInfo } = useContext(UserAuthContext) as UserAuthType;

  const fetchRaceData = async () => {
    fetch(`https://admin-vrraces.pavlosmelas.gr/api/races`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        const race = data.filter((row: any) => {
          return params.id == row.id;
        });
        setRaceData(race[0]);
      })
      .catch((error) => console.error(error));
  };
  const customRowRenderer = (record: any, index: any) => {
    return {
      onMouseEnter: (event: any) => {
        // Handle row hover event if needed
      },
      onMouseLeave: (event: any) => {
        // Handle row leave event if needed
      },
      className: classes.disableRowHover, // CSS class name for disabling row hover
    };
  };

  const handleChangeGender = (value: string) => {
    setSelectedGender(value);
    // Prevent scrolling to the top
    window.scrollTo({
      top: window.scrollY, // Maintain current scroll position
      behavior: "smooth", // Optional: Add smooth scrolling
    });
  };

  const handleChangeRange = (value: number) => {
    setSelectedAgeRange(value);
    // Prevent scrolling to the top
    window.scrollTo({
      top: window.scrollY, // Maintain current scroll position
      behavior: "smooth", // Optional: Add smooth scrolling
    });
  };

  const handleChange = (value: string) => {
    console.log(value);

    window.location.href = "/results/" + value;
    // const selectedRace = raceList.find((race) => race.title === value);
    // if (selectedRace) {
    //     setRaceData(selectedRace.id);
    // }
  };
  const filterData = () => {
    let filteredData: DataType[] = originalData;
    

    if (selectedGender !== "") {
      filteredData = filteredData.filter(
        (item) => item.gender === selectedGender
      );
    }

    if (selectedAgeRange !== 0) {
      const ageRange = ranges.find((range) => range.id === selectedAgeRange);

      if (ageRange) {
        const [minAge, maxAge] = ageRange.name.split("-");
        
        filteredData = filteredData.filter(
          (item) => item.age >= Number(minAge) && item.age <= Number(maxAge)
        );
      }
    }

    setData(filteredData);
    
  };
  console.log(data);
  useEffect(() => {
    getRaces();
    fetchRaceData();
    fetchData();
    getRanges();
  }, []);

  useEffect(() => {
    const filteredData = originalData.filter(
      (item: DataType) =>
        selectedGender === "" || item.gender === selectedGender
    );
    setData(filteredData);
  }, [selectedGender, selectedAgeRange, originalData, ranges]);

  useEffect(() => {
    filterData();
  }, [selectedGender, selectedAgeRange]);

  if (!raceData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <HelmetComponent
        title={t("VR-RACES") || undefined}
        descrition={t("SEO2 Home description") || undefined}
      />
      <div className={classes.container}>
        <div
          className={classes.info}
          style={{ marginTop: 34, marginBottom: 43 }}
        >
          <div className={classes.textBlack}>
            {t("ΑΠΟΤΕΛΕΣΜΑΤΑ ΑΓΩΝΩΝ ΔΡΟΜΟΥ")}
          </div>

          <div className={classes.main_title}>
            <Select
              className={classes.select_btn}
              defaultValue="Αγώνες"
              style={{
                width: "100%",
                maxWidth: 500,
              }}
              onChange={handleChange}
            >
              {raceList.map((race) => (
                <Option key={race.id} value={race.id}>
                  {race.title}
                </Option>
              ))}
            </Select>
            <br />
            <br />
            {raceData.title}
          </div>
          <div className={classes.main_date}>
            {moment(raceData.start_date, "DD/MM/YYYY HH:mm").format(
              "DD/MM/YYYY HH:mm"
            )}{" "}
            -{" "}
            {moment(raceData.end_date, "DD/MM/YYYY HH:mm").format(
              "DD/MM/YYYY HH:mm"
            )}
          </div>
          <Row justify="center" className={classes.info_col}>
            <Col xs={24} sm={24} md={24} lg={12}>
              <div className={classes.number}>
                <img
                  src={`https://admin-vrraces.pavlosmelas.gr/${raceData.banner[0].url}`}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  alt="banner"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <div className={classes.info}>
                <span>{parse(raceData.description)}</span>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className={classes.results}
          style={{ marginTop: 34, marginBottom: 43 }}
        >
          <div className={classes.selects}>
            <Select
              className={classes.select_btn}
              defaultValue="ΦΥΛΟ"
              style={{ width: 167 }}
              onChange={handleChangeGender}
              options={[
                { value: "Άρρεν", label: "Άρρεν" },
                { value: "Θήλυ", label: "Θήλυ" },
              ]}
            />
            <Select
              className={classes.select_btn}
              placeholder="ΗΛΙΚΙΕΣ"
              style={{ width: 167 }}
              onChange={handleChangeRange}
              options={ranges.map((range) => ({
                value: range.id,
                label: range.name,
              }))}
            />
          </div>
          <Table
            className={classes.table}
            columns={columns}
            onRow={customRowRenderer}
            dataSource={data}
            pagination={{ defaultPageSize: 5, showSizeChanger: false }}
            size="middle"
            scroll={{ x: 'max-content' }}
          />
        </div>
      </div>
    </>
  );
};

export default Results;
