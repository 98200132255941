import { useState, useEffect } from "react";
import { EditOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker } from 'antd';
import classes from './styles.module.css';
import EditIcon from '../../../img/edit2.png';
import { Row, Col } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import localeData from 'antd/es/date-picker/locale/el_GR'; // Import Greek locale data

type Props = {
  defaultValue: string;
  placeholder: string;
  setVal: (value: string) => void;
};

export const InfoDate: React.FC<Props> = ({ defaultValue, placeholder, setVal }) => {
  const [selectedDate, setSelectedDate] = useState<string | null>(defaultValue);
  const [disabled, setDisabled] = useState(true);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (!clicked) {
      setVal(defaultValue);
    } else {
      setVal(selectedDate || "");
    }
  }, [clicked, defaultValue, setVal, selectedDate]);

  const handleEditClick = () => {
    setClicked(true);
    setDisabled((prevState) => !prevState);
  };

  const handleSaveClick = () => {
    setClicked(true);
    setDisabled(true);
    // save logic goes here
  };

  const handleDateChange = (date: Dayjs | null, dateString: string) => {
    setSelectedDate(dateString);
  };

  return (
    <Row>
      <label className={classes.label}>
        {!disabled && clicked ? (
          <DatePicker
            className={classes.genderSelect}
            value={selectedDate ? dayjs(selectedDate) : null}
            onChange={handleDateChange}
            placeholder=""
            locale={localeData} // Set Greek locale for DatePicker
          />
        ) : (
          <DatePicker
            className={classes.genderSelect}
            value={selectedDate ? dayjs(selectedDate) : null}
            onChange={handleDateChange}
            placeholder={placeholder}
            disabled={disabled}
            open={disabled && clicked} // Open the DatePicker when disabled and clicked
            locale={localeData} // Set Greek locale for DatePicker
          />
        )}

        {disabled && (
          <button
            type="button"
            onClick={handleEditClick}
            className={classes.edit}
          >
            <img src={EditIcon} alt="edit" />
          </button>
        )}

        {!disabled && (
          <button
            type="button"
            onClick={handleSaveClick}
            className={classes.edit}
          >
            <Col>
              <img src={EditIcon} alt="edit" />
            </Col>
          </button>
        )}
      </label>
    </Row>
  );
};
