import React, { useState, useEffect, useContext } from "react";
import classes from "./Upload.module.css";
import { useTranslation } from "react-i18next";
import { Row, Button, Select, Upload, Checkbox, notification } from 'antd';
import HelmetComponent from '../../components/Helmet/Helmet';
import axios from 'axios';
import { UploadOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { UploadChangeParam } from "antd/es/upload";
import { UserAuthContext } from '../../context/UserAuthContext';
import { UserAuthType, UserInfo } from '../../types/UserAuth';
import type { NotificationPlacement } from 'antd/es/notification/interface';

const { Option } = Select;

const UploadPage: React.FC = () => {
    const { t } = useTranslation();
    const [raceList, setRaceList] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [raceData, setRaceData] = useState<number>(0);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isChecked, setIsChecked] = useState(false);
    const { userInfo } = useContext(UserAuthContext) as UserAuthType;
    const [success, setSuccess] = useState<boolean>(false)
    const [uploadKey, setUploadKey] = useState(Date.now());
    const [selectedFileName, setSelectedFileName] = useState('');

    const getRaces = async () => {
        try {
            const url = 'https://admin-vrraces.pavlosmelas.gr/api/races';
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${userInfo.access_token}`,
                },
            });

            const currentDate = new Date();

            const filteredRaces = response.data.filter((race: any) => {
                const raceDateParts = race.end_date.split(' ');
                const raceDate = raceDateParts[0].split('/'); // assuming date format: dd/mm/yyyy
                const raceTime = raceDateParts[1].split(':'); // assuming time format: hh:mm:ss
                const year = parseInt(raceDate[2]);
                const month = parseInt(raceDate[1]) - 1; // month is 0-based in JavaScript
                const day = parseInt(raceDate[0]);
                const hour = parseInt(raceTime[0]);
                const minute = parseInt(raceTime[1]);
                const second = parseInt(raceTime[2]);

                const raceEndDate = new Date(year, month, day, hour, minute, second);

                return raceEndDate < currentDate;
            });

            setRaceList(filteredRaces);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching races:", error);
        }
    };

    const handleFileUpload: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
        if (isChecked) {
            event.preventDefault();
            if (selectedFile) {
                try {
                    const formData = new FormData();
                    formData.append('race_id', raceData.toString());
                    formData.append('file', selectedFile);

                    const response = await axios.post(
                        'https://admin-vrraces.pavlosmelas.gr/api/gpx/upload',
                        formData,
                        {
                            headers: {
                                Accept: 'application/json',
                                Authorization: `Bearer ${userInfo.access_token}`,
                            },
                        }
                    );

                    console.log('Upload successful:', response.data);

                    if (response.data.valid===1) {
                        setSuccess(true);
                        setErrorMessage('');
                        setSelectedFile(null); // Clear the log file after successful upload
                        openNotification('top');
                    } else {
                        setErrorMessage('Το αρχείο δεν είναι έγκυρο!');
                        setSelectedFile(null);
                    }


                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        console.log("TEST inside if (axios.isAxiosError(error)) { ");
                        if (error.response) {
                            setErrorMessage(error.response.data.message);
                        } else {
                            setErrorMessage(error.message);
                        }
                    }
                }
            }
        } else {
            setSuccess(false);
            setErrorMessage('Υποχρεωτικό!');
        }
    };

    const handleChange = (value: string) => {
        const selectedRace = raceList.find((race) => race.title === value);
        if (selectedRace) {
            setRaceData(selectedRace.id);
        }
    };

    const handleFileChange = (info: UploadChangeParam) => {
        const fileList = info.fileList;
        if (fileList.length > 0) {
            const file = fileList[0].originFileObj as File;
            setSelectedFile(file);
            setSelectedFileName(file.name);
            setUploadKey(Date.now()); // Update the key to re-render the Upload component
        } else {
            setSelectedFile(null);
            setSelectedFileName('');
        }
    };

    const onChange = (e: CheckboxChangeEvent) => {
        setIsChecked(e.target.checked);
    };

    const openNotification = (placement: NotificationPlacement | undefined) => {
        notification.open({
            message: (
                <span style={{ fontFamily: 'Black' }}>Εισαγωγή Χρόνου</span>
            ),
            description: (
                <span style={{ color: '#23C552' }}>Το αρχείο μεταφορτώθηκε επιτυχώς!</span>
            ),
            placement,
            duration: 0,
        });
    };

    useEffect(() => {
        getRaces();
    }, []);


    return (
        <>
            <HelmetComponent title='Search' descrition='Αναζήτηση' />

            <div className={classes.container}>
                <div className={classes.info} style={{ marginTop: 34, marginBottom: 43 }}>
                    <div className={classes.textBlack}>
                        {t(`ΕΙΣΑΓΩΓΗ ΧΡΟΝΟΥ`)}
                    </div>
                    <Row justify="center">

                        <div className={classes.results} style={{ marginTop: 34, marginBottom: 43 }}>
                            <div className={classes.selects}>
                                <div className={classes.title}>
                                    Επιλογή Αγώνα
                                </div>
                                <Select
                                    className={classes.select_btn}
                                    defaultValue=""
                                    style={{ width: 631, marginBottom: 35 }}
                                    onChange={handleChange}
                                >
                                    {raceList.map((race) => (
                                        <Option
                                            key={race.title}
                                            value={race.title}
                                        >
                                            {race.title}
                                        </Option>
                                    ))}
                                </Select>
                                <div className={classes.title}>
                                    Επιλογή Αρχείου
                                </div>
                                <div className={classes.upload_container}>
                                    {selectedFileName && (
                                        <span className={classes.selected_file}>{selectedFileName}</span>
                                    )}
                                    <Upload
                                        key={uploadKey} // Update the key to re-render the Upload component
                                        showUploadList={false}
                                        beforeUpload={() => false}
                                        onChange={handleFileChange}
                                    >
                                        <Button className={classes.upload_btn}>
                                            ΕΠΙΛΟΓΗ ΑΡΧΕΙΟΥ
                                        </Button>
                                    </Upload>
                                </div>

                                <br />
                                <Checkbox className={classes.policy} onChange={onChange}>
                                    Συμφωνώ με τους όρους δημοσιοποίησης και επεξεργασίας προσωπικών δεδομένων όπως αυτοί περιγράφονται στους <a href="/terms-of-use" target="_blank" rel="noopener noreferrer"><u>ΟΡΟΥΣ ΧΡΗΣΗΣ</u></a>
                                </Checkbox>
                                {errorMessage && <p style={{ fontSize: '1rem', color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
                                <br />
                                <div className={classes.login_container}>
                                    <Button className={classes.upload_btn} onClickCapture={handleFileUpload}>
                                        ΕΙΣΑΓΩΓΗ
                                    </Button>
                                    <br />
                                    <div className={classes.success}>
                                        {success === true ? "Το αρχείο μεταφορτώθηκε επιτυχώς!" : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default UploadPage;
