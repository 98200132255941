import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Select } from 'antd';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  AimOutlined,
  CalendarOutlined,
  AlertOutlined,
  NodeIndexOutlined,
  SearchOutlined,
  MenuOutlined,
  CloseOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  UserOutlined,
} from '@ant-design/icons';
import classes from './Navbar.module.css';
import updatedSidebarData from './SidebarData';
import SubMenu from './SubMenu';
import { UserAuthContext } from '../../context/UserAuthContext';
import { UserAuthType, UserInfo } from '../../types/UserAuth';

const Navbar = () => {
  const { userInfo } = useContext(UserAuthContext) as UserAuthType;
  const [isLoggedIn, setIsLoggedIn] = useState(!!userInfo?.access_token);
  const [sidebar, setSidebar] = useState(false);
  const [submenu, setSubmenu] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { t } = useTranslation();
  const location = useLocation();
  const navbarRef = useRef<HTMLDivElement>(null);

  const showSidebar = (tab: any) => {
    if (tab.toggle) setSidebar(!sidebar);
  };

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  const closeNavbar = () => {
    setSidebar(false);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', changeWidth);
    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, []);

  useEffect(() => {
    setIsLoggedIn(!!userInfo?.access_token);
  }, [userInfo]);

  const handleOutsideClick = (event: any) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      closeNavbar();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div>
      {screenWidth > 1280 && (
        <ul className={[classes.list, classes.items].join(' ')}>
          {updatedSidebarData.map((item: any, index: any) => (
            <NavLink
              key={item.id}
              to={item.path}
              onClick={toggleNav}
              className={`${classes.navLink} ${location.pathname === item.path ? classes.active : ''}`}
            >
              {t(item.title)} {item.iconClosed ? <CaretDownOutlined onClick={() => setSubmenu(!submenu)} /> : ''}
            </NavLink>
          ))}
        </ul>
      )}

      <button onClick={() => setSidebar(!sidebar)} className={classes.btn}>
        <MenuOutlined />
      </button>

      <nav
        ref={navbarRef}
        className={sidebar ? `${classes.navMenu} ${classes.navMenuActive}` : classes.navMenu}
        onClick={closeNavbar}
      >
        <ul className={classes.navMenuItems}>
          <li className={classes.navbarToggle}>
            <Link to="#" className={classes.menuBars}>
              <CloseOutlined className={classes.closeIcon} onClick={() => setSidebar(!sidebar)} />
            </Link>
          </li>
          {updatedSidebarData.map((item: any, index: any) => (
            <li key={index} className={classes.navText} onClick={() => showSidebar(item)}>
              <SubMenu item={item} onClick={() => showSidebar(item)} />
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
