import { useState, useEffect } from "react";
import { EditOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, Select } from 'antd';
import classes from './styles.module.css';
import EditIcon from '../../../img/edit2.png';
import { Row, Col } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

const { Option } = Select;

type Props = {
  defaultValue: string;
  placeholder: string;
  setVal: (value: string) => void;
};

export const InfoSelect: React.FC<Props> = ({ defaultValue, placeholder, setVal }) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!isEditing) {
      setVal(selectedValue || defaultValue);
    }
  }, [isEditing]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    // save logic goes here
  };

  const handleSelect = (value: string) => {
    if (value !== selectedValue) {
      setSelectedValue(value);
      setVal(value); // Save the value immediately when an option is selected
    }
    setIsEditing(false);
  };

  return (
    <Row>
      <label className={classes.label}>
        {isEditing ? (
          <Select
            className={classes.genderSelect}
            value={selectedValue}
            onChange={handleSelect}
            placeholder={placeholder}
            open
          >
            <Option value="Άρρεν">Άρρεν</Option>
            <Option value="Θύλη">Θύλη</Option>
          </Select>
        ) : (
          <Select
            className={classes.genderSelect}
            value={selectedValue || defaultValue}
            placeholder={placeholder}
            disabled
          >
            <Option value={defaultValue}>{defaultValue}</Option>
          </Select>
        )}

        {!isEditing && (
          <button
            type="button"
            onClick={handleEditClick}
            className={classes.edit}
          >
            <img src={EditIcon} alt="edit" />
          </button>
        )}

        {isEditing && (
          <button
            type="button"
            onClick={handleSaveClick}
            className={classes.edit}
          >
            <Col>
              <img src={EditIcon} alt="edit" />
            </Col>
          </button>
        )}
      </label>
    </Row>
  );
};
