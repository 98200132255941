import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import './Calendar.css';
import 'react-calendar/dist/Calendar.css';

const MyCalendar = ({ onSelectMonth, onSelectYear, month, year }) => {
  const [date, setDate] = useState(new Date(year, month, 0));
  const currentDate = new Date();

  useEffect(() => {
    const selectedMonth = date.getMonth() + 1;
    const selectedYear = date.getFullYear();
    onSelectMonth(selectedMonth);
    onSelectYear(selectedYear);
  }, [date, onSelectMonth, onSelectYear]);

  const tileDisabled = ({ date, view }) => {
    if (view === 'year') {
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      return year < currentYear || (year === currentYear && month < currentMonth);
    }
    return false;
  };

  return (
    <div className='app'>
      <div className='calendar-container'>
        <Calendar
          onChange={setDate}
          value={date}
          maxDetail='year'
          formatMonth={(locale, date) => date.toLocaleString(locale, { month: 'short' })}
          locale='el-GR'
          prev2Label={null}
          next2Label={null}
          tileDisabled={tileDisabled}
        />
      </div>
      <p className='text-center'></p>
    </div>
  );
};

export default MyCalendar;
