export const SidebarData = [
  {
    id: 1,
    title: 'ΠΩΣ ΛΕΙΤΟΥΡΓΕΙ',
    path: '/about',
    state: { page: 'about' },
    toggle: true,
  },
  {
    id: 2,
    title: 'ΒΡΕΣ ΕΝΑΝ ΑΓΩΝΑ',
    path: '/search',
    state: { page: 'search' },
    toggle: true,
  },
  {
    id: 3,
    title: 'ΜΟΙΡΑΣΟΥ ΤΟΝ ΧΡΟΝΟ ΣΟΥ',
    path: '/upload',
    state: { page: 'upload' },
    toggle: true,
  },
  {
    id: 4,
    title: 'ΔΕΣ ΟΛΟΥΣ ΤΟΥΣ ΧΡΟΝΟΥΣ',
    path: '/results',
    // state: { page: 'results' },
    toggle: true,
  },
];

// Check if the user is logged in
const userInfo = window.localStorage.getItem('userInfo');
const parsedUserInfo = userInfo ? JSON.parse(userInfo) : {};
const isUserLoggedIn = parsedUserInfo.access_token && parsedUserInfo.access_token !== '';

// Conditionally update the sidebar item based on the user's login status
const accountSidebarItem = isUserLoggedIn
  ? {
      id: 5,
      title: 'ΛΟΓΑΡΙΑΣΜΟΣ',
      path: '/account',
      state: { page: 'account' },
      toggle: true,
    }
  : {
      id: 5,
      title: 'ΕΙΣΟΔΟΣ/ΕΓΓΡΑΦΗ',
      path: '/login',
      state: { page: 'login' },
      toggle: true,
    };

// Add the sidebar item to the SidebarData array
const updatedSidebarData = [...SidebarData, accountSidebarItem];

export default updatedSidebarData;
