import React from "react";
import { Row, Col } from "antd";
import classes from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HelmetComponent from "../../components/Helmet/Helmet";

const PageNotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <HelmetComponent title="Error page 404" descrition="Error page 404" />

      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className={classes.container}>
            <div className={classes.textWrapper}>
              <h1>Error Page 404</h1>
              <h2>{t("Δυστυχώς")}</h2>
              <h3>{t("δεν μπορέσαμε να βρούμε αυτήν τη σελίδα")}</h3>
              <p className={classes.link}>
                <Link to="/">{t("Πίσω στην Αρχική Σελίδα")}</Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PageNotFound;
