import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, DatePicker, Row, Select } from "antd";
import dayjs, { Dayjs } from "dayjs";
import locale from "antd/es/date-picker/locale/el_GR";
import axios from "axios";
import UserAuthProvider, {
  UserAuthContext,
} from "../../../context/UserAuthContext";
import { UserAuthType } from "../../../types/UserAuth";

import classes from "./css/SearchSelectRaces.module.css";
import run from "../../../img/run-icon.png";
import tablet from "../../../img/tablet_icon.png";
import smartwatch from "../../../img/smartwatch_icon.png";

interface Distance {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

const SearchSelectRaces: React.FC = () => {
  const [distances, setDistances] = useState<Distance[]>([]);
  const [distanceSearchId, setDistanceSearchId] = useState<number | undefined>(
    undefined
  );
  const [searchYear, setSearchYear] = useState<number | null>(null);
  const [searchMonth, setSearchMonth] = useState<number | null>(null);
  const { userInfo } = useContext(UserAuthContext) as UserAuthType;

  const handleChange = (value: number | undefined) => {
    console.log(`selected ${value}`);
    setDistanceSearchId(value);
  };

  const onChange = (date: Dayjs | null, dateString: string) => {
    if (date) {
      const year = date.year();
      const month = date.month();

      setSearchYear(year);
      setSearchMonth(month + 1);
    } else {
      setSearchYear(null);
      setSearchMonth(null);
    }
  };

  const getDistances = async () => {
    try {
      const url = "https://admin-vrraces.pavlosmelas.gr/api/distances";
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      setDistances(response.data);
    } catch (error: any) {
      console.error("Error fetching data:", error);
      // if (error.response?.data?.message === "Unauthorized user") {
      //     setErrorMessage("Please log in to search for distances!");
      // }
    }
  };

  useEffect(() => {
    getDistances();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.info} style={{ marginBottom: 43 }}>
        <div className={classes.textBlack}>ΕΙΚΟΝΙΚΟΙ ΑΓΩΝΕΣ ΔΡΟΜΟΥ</div>
        <Row justify="center">
          <Col xl={8} className={classes.select}>
            <Select
              className={classes.select_btn}
              style={{ width: 167 }}
              onChange={handleChange}
              options={distances.map((distance) => ({
                value: distance.id.toString(),
                label: distance.name,
              }))}
              placeholder="ΑΠΟΣΤΑΣΗ"
            />
            <div className={classes.icon_col}>
              <img src={run} className={classes.icon} alt="external-link" />
            </div>
            <div className={classes.col_title}>
              <span>Βρες και πάρε μέρος σε αγώνες εικονικού δρόμου</span>
            </div>
            <div className={classes.col_desc}>
              <span>
                Επίλεξε αγώνα, βρες την απόσταση που σου ταιριάζει και
                πραγματοποίησε εγγραφή.
              </span>
            </div>
          </Col>
          <Col xl={8} className={classes.select}>
            <DatePicker
              onChange={onChange}
              locale={locale}
              className={classes.datePicker}
              placeholder="ΗΜΕΡΟΜΗΝΙΑ"
              picker="month"
            />
            <div className={classes.icon_col}>
              <img src={tablet} className={classes.icon} alt="external-link" />
            </div>
            <div className={classes.col_title}>
              <span>Κατέβασε την εφαρμογή και ανέβασε τον χρόνο σου</span>
            </div>
            <div className={classes.col_desc}>
              <span>
                Μετά την ολοκλήρωση ενός αγώνα, μπορείς να υποβάλεις το
                αποτέλεσμά σου στην πλατφόρμα.
              </span>
            </div>
          </Col>
          <Col xl={8} className={classes.select}>
            <Link
              to={`/search?distance=${distanceSearchId}&month=${searchMonth}&year=${searchYear}`}
            >
              <Button shape="round" className={classes.select_search_btn}>
                ΑΝΑΖΗΤΗΣΗ
              </Button>
            </Link>
            <div className={classes.icon_col}>
              <img
                src={smartwatch}
                className={classes.icon}
                alt="external-link"
              />
            </div>
            <div className={classes.col_title}>
              <span>Σύγκρινε τα αποτελέσματά σου με των άλλων</span>
            </div>
            <div className={classes.col_desc}>
              <span>
                Δες τους χρόνους σου σε σχέση με τον συναγωνισμό και προσπάθησε
                να βελτιωθείς!
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SearchSelectRaces;
