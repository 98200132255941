import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import classes from "./Login.module.css";
import { useTranslation } from "react-i18next";
import { Row, Button, Input, Form } from "antd";
import { UserAuthContext } from "../../context/UserAuthContext";
import { UserAuthType, UserInfo } from "../../types/UserAuth";

const Login = () => {
  const { t } = useTranslation();

  const { userInfo, saveUserInfo } = useContext(
    UserAuthContext
  ) as UserAuthType;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    const url = `https://admin-vrraces.pavlosmelas.gr/api/login?email=${values.email}&password=${values.password}`;

    try {
      await form.validateFields();
      const response = await axios.get(url);

      const newUserInfo: UserInfo = {
        id: response.data.id,
        name: response.data.name,
        surname: response.data.surname,
        club: response.data.club,
        itra: response.data.itra,
        gender: response.data.gender,
        residence: response.data.residence,
        email: response.data.email,
        date_of_birth: response.data.date_of_birth,
        place_of_birth: response.data.place_of_birth,
        access_token: response.data.access_token,
        name_is_public: Boolean(response.data.name_is_public),
        surname_is_public: Boolean(response.data.surname_is_public),
        club_is_public: Boolean(response.data.club_is_public),
        itra_is_public: Boolean(response.data.itra_is_public),
        gender_is_public: Boolean(response.data.gender_is_public),
        email_is_public: Boolean(response.data.email_is_public),
        place_of_birth_is_public: Boolean(
          response.data.place_of_birth_is_public
        ),
        date_of_birth_is_public: Boolean(response.data.date_of_birth_is_public),
        residence_is_public: Boolean(response.data.residence_is_public),
      };

      localStorage.setItem("userInfo", JSON.stringify(newUserInfo));

      saveUserInfo(newUserInfo);

      console.log("Login successful");
      console.log("USER: ", userInfo);
      window.location.href = "/";
    } catch (error: any) {
      console.log(error);

      if (
        error.response.data.error === "authentication error" ||
        axios.isAxiosError(error)
      ) {
        const errorMessage = "Η τιμή δεν είναι έγκυρη. Δοκιμάστε ξανά!";
        setErrorMessage(errorMessage);
        console.log("pass auth");
      }
    }
  };

  useEffect(() => {
    const storedUserInfo = localStorage.getItem("userInfo");
    if (storedUserInfo) {
      const parsedUserInfo = JSON.parse(storedUserInfo);
      saveUserInfo(parsedUserInfo);
    }
  }, []);

  useEffect(() => {
    if (errorMessage) {
      form.setFields([
        {
          name: "email",
          errors: [errorMessage],
        },
      ]);
    } else {
      form.setFields([]);
    }
  }, [errorMessage, form]);

  return (
    <>
      <div className={classes.container}>
        <div
          className={classes.info}
          style={{ marginTop: 34, marginBottom: 43 }}
        >
          <div className={classes.textBlack}>{t("ΕΙΣΟΔΟΣ ΧΡΗΣΤΗ")}</div>
          <Row justify="center">
            <div
              className={classes.results}
              style={{ marginTop: 34, marginBottom: 43 }}
            >
              <div className={classes.title}>{t("Email")}</div>
              <Form
                form={form}
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Υποχρεωτικό πεδίο!" },
                    { type: "email", message: "Μη έγκυρη μορφή!" },
                  ]}
                  validateStatus={errorMessage ? "error" : undefined}
                  help={errorMessage}
                >
                  <Input className={classes.inputForm} placeholder="" />
                </Form.Item>
                <div className={classes.title}>{t("Συνθηματικό Χρήστη")}</div>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "Υποχρεωτικό πεδίο!" }]}
                  validateStatus={errorMessage ? "error" : undefined}
                  help={errorMessage}
                >
                  <Input
                    className={classes.inputForm}
                    type="password"
                    placeholder=""
                  />
                </Form.Item>

                <br />
                <Form.Item>
                  <div className={classes.login_container}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={classes.upload_btn}
                    >
                      ΕΙΣΟΔΟΣ
                    </Button>
                  </div>
                </Form.Item>
                <a
                  className={classes.login_form_forgot}
                  href="/forgot-password"
                >
                  <u>Έχω ξεχάσει το Συνθηματικό μου</u>
                </a>
                <br />
                <a className={classes.login_form_forgot} href="/register">
                  <u>Εγγραφή</u>
                </a>
              </Form>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Login;
