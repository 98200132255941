import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Select, Space, Table, Divider } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import HelmetComponent from "../../components/Helmet/Helmet";
import run2 from "../../img/run2.png";
import { UserAuthContext } from "../../context/UserAuthContext";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import parse from "html-react-parser";
import moment from "moment";
import axios from "axios";
import classes from "./Results.module.css";
const { Option } = Select;

interface DataType {
  position: number;
  completion_time: number;
  name: string;
  surname: string;
  user_id: number;
  residence: string;
  gender: string;
  age: number;
}

interface ResponseType {
  id: number;
  user_id: number;
  completion_time: any;
  average_speed: number;
  distance: string;
  status: string;
  created_at: string;
  updated_at: string;
  race_id: number;
  gpx_path: string;
  user: {
    id: number;
    name: string;
    email: string;
    email_verified_at: string | null;
    created_at: string;
    updated_at: string;
    surname: string;
    gender: string;
    date_of_birth: string;
    place_of_birth: string;
    residence: string;
    club: string;
    ITRA: string | null;
    approves_result_sharing: number;
    media: any[];
  };
}

interface Range {
  id: number;
  value: string;
  label: string;
  name: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const ResultsDefault = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [raceData, setRaceData] = useState<any>();
  const [data, setData] = useState<DataType[]>([]); // Update to use state
  const [selectedGender, setSelectedGender] = useState<string>("");
  const [selectedAgeRange, setSelectedAgeRange] = useState<number>(0);
  const [originalData, setOriginalData] = useState<DataType[]>([]);
  const [ranges, setRanges] = useState<Range[]>([]);
  const [raceList, setRaceList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  // const [tableParams, setTableParams] = useState<TableParams>({
  //     pagination: {
  //         current: 1,
  //         pageSize: 10,
  //     },
  // });

  const calculateAge = (dateOfBirth: string) => {
    const age = moment().diff(dateOfBirth, "years");
    return age;
  };
  const getRaces = async () => {
    try {
      const url = "https://admin-vrraces.pavlosmelas.gr/api/races";
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      const currentDate = new Date();

      const filteredRaces = response.data.filter((race: any) => {
        const raceDateParts = race.end_date.split(" ");
        const raceDate = raceDateParts[0].split("/"); // assuming date format: dd/mm/yyyy
        const raceTime = raceDateParts[1].split(":"); // assuming time format: hh:mm:ss
        const year = parseInt(raceDate[2]);
        const month = parseInt(raceDate[1]) - 1; // month is 0-based in JavaScript
        const day = parseInt(raceDate[0]);
        const hour = parseInt(raceTime[0]);
        const minute = parseInt(raceTime[1]);
        const second = parseInt(raceTime[2]);

        const raceEndDate = new Date(year, month, day, hour, minute, second);
        return raceEndDate < currentDate;
      });

      setRaceList(filteredRaces);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching races:", error);
    }
  };

  const { t } = useTranslation();

  const { userInfo } = useContext(UserAuthContext) as UserAuthType;

  const customRowRenderer = (record: any, index: any) => {
    return {
      onMouseEnter: (event: any) => {
        // Handle row hover event if needed
      },
      onMouseLeave: (event: any) => {
        // Handle row leave event if needed
      },
      className: classes.disableRowHover, // CSS class name for disabling row hover
    };
  };

  const handleChange = (value: string) => {
    navigate("/results/" + value);
  };

  useEffect(() => {
    getRaces();
  }, []);

  // if (!raceData) {
  //     return <div>Loading...</div>;
  // }

  return (
    <>
      <HelmetComponent
        title={t("VR-RACES") || undefined}
        descrition={t("SEO2 Home description") || undefined}
      />
      <div className={classes.container}>
        <div
          className={classes.info}
          style={{ marginTop: 34, marginBottom: 43 }}
        >
          <div className={classes.textBlack}>
            {t("ΑΠΟΤΕΛΕΣΜΑΤΑ ΑΓΩΝΩΝ ΔΡΟΜΟΥ")}
          </div>
          <Row justify="center" className={classes.info_col}>
            <div className={classes.info}>
              <span style={{ textAlign: "center" }}>
                Επέλεξε αγώνα και δες τα αποτελέσματα!
              </span>
            </div>
          </Row>
          <div className={classes.main_title}>
            <Select
              className={classes.select_btn}
              defaultValue="Αγώνες"
              style={{ width: "100%", maxWidth: 500 }}
              onChange={handleChange}
            >
              {raceList.map((race) => (
                <Option key={race.id} value={race.id}>
                  {race.title}
                </Option>
              ))}
            </Select>
          </div>
          <Row justify="center" className={classes.info_col}>
            <Col xs={24} sm={24} md={24} lg={12}>
              <div className={classes.number}>
                {/* <img
                                    src={`https://admin-vrraces.pavlosmelas.gr/${raceData.banner[0].url}`}
                                    style={{ width: 324, objectFit: 'cover', maxHeight: 153, objectPosition: 'center' }}
                                    alt="banner"
                                /> */}
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <div className={classes.info}>
                {/* <span>{parse(raceData.description)}</span> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ResultsDefault;
