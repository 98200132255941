import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Search from "./pages/Search/Search";
import SingleResult from "./pages/SingleResult/SingleResult";
import UploadPage from "./pages/Upload/Upload";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Forgot from "./pages/Forgot/Forgot";
import Account from "./pages/Account/Account";
import { Styles } from "./styles/styles";
import { I18nextProvider } from "react-i18next";
import i18n from "./translation";
import UserAuthProvider from "./context/UserAuthContext";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import ResultsDefault from "./pages/Results/Results";
import PageNotFound from "./pages/404";
import "./App.css";

const App = () => {
  return (
    <UserAuthProvider>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Styles />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/search" element={<Search />} />
            <Route path="/results" element={<ResultsDefault />} />
            <Route path="/results/:id" element={<SingleResult />} />
            <Route path="/upload" element={<UploadPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<Forgot />} />
            <Route path="/account" element={<Account />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </I18nextProvider>
      </BrowserRouter>
    </UserAuthProvider>
  );
};

export default App;
