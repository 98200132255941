import classes from "./Forgot.module.css";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Input, Checkbox, Form, Modal, } from 'antd';
import HelmetComponent from '../../components/Helmet/Helmet';
import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { UserAuthContext } from '../../context/UserAuthContext';
import { UserAuthType, UserInfo } from '../../types/UserAuth';
import axios from "axios";

const Forgot = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const { deleteUserInfo } = useContext(UserAuthContext) as UserAuthType;
    const [showModal, setShowModal] = useState(false);

    const onFinish = async (values: any) => {
        console.log('Received email: ', values.email);
        const url = `https://admin-vrraces.pavlosmelas.gr/api/password-reset`;

        try {
            const response = await axios.post(url, {
                email: values.email
            });
            console.log("RESPONSE: ", response);
            deleteUserInfo();
            
            Modal.success({
                title: 'ΕΠΙΤΥΧΕΣ ΑΙΤΗΜΑ!',
                content: 'Παρακαλούμε ελέγξετε στα Εισερχόμενα και στα Spam του email σας για περισσότερες πληροφορίες.',
                onOk: () => {
                    window.location.href = '/';
                }
            });
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <>
            <HelmetComponent title='Search' descrition='Αναζήτηση' />

            <div className={classes.container}>
                <div className={classes.info} style={{ marginTop: 34, marginBottom: 43 }}>
                    <div className={classes.textBlack}>
                        {t(`ΥΠΕΝΘΥΜΙΣΗ ΣΥΝΘΗΜΑΤΙΚΟΥ`)}
                    </div>
                    <Row justify="center">

                        <div className={classes.results} style={{ marginTop: 34, marginBottom: 43 }}>

                            <div className={classes.title}>
                                {t('Email')}
                            </div>
                            <Form
                                name="reset_login"
                                className="login-form"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    name="email"
                                    rules={[{ required: true, message: 'Παρακαλούμε εισάγετε email!' }]}
                                >
                                    <Input className={classes.inputForm} placeholder="" />
                                </Form.Item>
                                <br />
                                <Form.Item>
                                    <div className={classes.login_container}>
                                        <Button type="primary" htmlType="submit" className={classes.upload_btn}>ΑΠΟΣΤΟΛΗ ΝΕΟΥ ΣΥΝΘΗΜΑΤΙΚΟΥ</Button>
                                    </div>
                                </Form.Item>
                            </Form>
                        </div>
                    </Row>

                </div>
            </div >
        </>
    )
}

export default Forgot;