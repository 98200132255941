import React, { useState, useEffect } from "react"
import { Row, Col, Select } from 'antd'
import { EnvironmentOutlined, ClockCircleOutlined, SearchOutlined, HeartOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom"
import classes from "./Header.module.css"
import i18n from "i18next"
import { useTranslation } from "react-i18next";
import logo from "../../img/logo192.png"
import Navbar from "./Navbar";
import { Button } from 'antd';

const Header = () => {

    const { t } = useTranslation()

    return (
        <>
            <div className={[classes.header, classes.flexContainer].join(" ")}>
                <div className={classes.containerBottom}>
                    <div className={classes.Rectangle1}>
                        <Row justify="center">
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <div className={classes.app_logo}>
                                    <Link to="/" state={{ page: 'home' }} ><img src="../../img/logo.png" alt="logo" /></Link>
                                </div>
                            </Col>
                            <Col lg={24} md={24} sm={24} xs={24} >
                                <Link to="/" state={{ page: 'home' }} >
                                    <div className={classes.TitleHeader}>
                                        {t('VR')}<div style={{ fontWeight: 900 }}>{t('RACES')}</div>
                                    </div>
                                    <div className={classes.TextHeader}>
                                        {t('ΔΗΜΟΣ')}&nbsp;<div style={{ fontWeight: 900, minWidth: '8rem' }}>{t('ΠΑΥΛΟΥ ΜΕΛΑ')}</div>
                                    </div>
                                </Link>
                            </Col>

                        </Row>
                    </div>
                    
                    <Navbar />

                </div>
            </div>
        </>
    );
};

export default Header