import { useState, useContext, useEffect } from "react";
import classes from "./Account.module.css";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Input, Checkbox, Form, Table, Modal, DatePicker } from 'antd';
import HelmetComponent from '../../components/Helmet/Helmet';
import type { DatePickerProps } from 'antd';
import locale from 'antd/es/date-picker/locale/el_GR';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/el';
import { InfoInput } from "./InfoInput/InfoInput";
import { InfoDate } from "./InfoDate/InfoDate";
import edit from '../../img/edit2.png';
import type { ColumnsType } from 'antd/es/table';
import { UserAuthContext } from '../../context/UserAuthContext';
import Cookies from "js-cookie";
import { UserAuthType, UserInfo } from '../../types/UserAuth';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { InfoSelect } from "./InfoSelect/InfoSelect";
import { log } from "util";

interface DataType {
    date: string;
    completion_time: string;
    place: string,
    title: string
}


const Account = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    dayjs.locale('el');

    const { getUserInfo, deleteUserInfo } = useContext(UserAuthContext) as UserAuthType;
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState<{ old_password: string, new_password: string }>({ old_password: '', new_password: '' });
    const [data, setData] = useState([]);




    const [userInfo, setUserInfo] = useState<UserInfo>(() => {

        const storedUserInfo = localStorage.getItem('userInfo');
        if (storedUserInfo) {
            return JSON.parse(storedUserInfo);
        } else {
            return { name: '', surname: '', club: '', itra: '', gender: '', email: '', residence: '', date_of_birth: '' };
        }
    });

    const [name, setName] = useState<string>(userInfo.name);
    const [surname, setSurname] = useState<string>(userInfo.surname);
    const [club, setClub] = useState<string>(userInfo.club);
    const [itra, setItra] = useState<string>(userInfo.itra);
    const [gender, setGender] = useState<string>(userInfo.gender);
    const [email, setEmail] = useState<string>(userInfo.email);
    const [residence, setResidence] = useState<string>(userInfo.residence);
    const [placeOfBirth, setPlaceOfBirth] = useState<string>(userInfo.place_of_birth);
    const [dateOfBirth, setDateOfBirth] = useState<string>("");
    const [name_is_public, setName_is_public] = useState<boolean>(userInfo.name_is_public);
    const [surname_is_public, setSurame_is_public] = useState<boolean>(userInfo.surname_is_public);
    const [email_is_public, setEmail_is_public] = useState<boolean>(userInfo.email_is_public);
    const [club_is_public, setClub_is_public] = useState<boolean>(userInfo.club_is_public);
    const [itra_is_public, setItra_is_public] = useState<boolean>(userInfo.itra_is_public);
    const [gender_is_public, setGender_is_public] = useState<boolean>(userInfo.gender_is_public);
    const [place_of_birth_is_public, setPlace_of_birth_is_public] = useState<boolean>(userInfo.place_of_birth_is_public);
    const [residence_is_public, setResidence_is_public] = useState<boolean>(userInfo.residence_is_public);
    const [date_of_birth_is_public, setDate_of_birth_is_public] = useState<boolean>(userInfo.date_of_birth_is_public);

    const requestChangePass = async (values: any) => {
        const url = `https://admin-vrraces.pavlosmelas.gr/api/user/password-change`;

        try {
            const response = await axios.post(url, {
                old_password: values.ch_password0,
                new_password: values.ch_password
            }, {
                headers: {
                    Authorization: `Bearer ${userInfo.access_token}`
                }
            });
            console.log("OK RESPONSE: ", response);

        } catch (error) {
            console.log(error);

            if (axios.isAxiosError(error)) {

                if (error.response) {
                    console.log("ERROR RESPONSE EXISTS");

                    const { errors } = error.response.data;
                    if (error.response && error.response.data && error.response.data.errors) {
                        const { errors } = error.response.data;
                        setErrorMessage({
                            old_password: errors.old_password ? errors.old_password[0] : '',
                            new_password: errors.new_password ? errors.new_password[0] : ''
                        });
                    }
                    console.log(errors);


                }
            }
        }
    };
    const handleNameChange = (value: string) => {
        setName(value);
    };
    const handleSurnameChange = (value: string) => {
        setSurname(value);
    };
    const handleClubChange = (value: string) => {
        setClub(value);
    };
    const handleItraChange = (value: string) => {
        setItra(value);
    };
    const handleGenderChange = (value: string) => {
        setGender(value);
    };
    const handleEmailChange = (value: string) => {
        setEmail(value);
    };
    const handleResidenceChange = (value: string) => {
        setResidence(value);
    };
    const handlePlaceOfBirthChange = (value: string) => {
        setPlaceOfBirth(value);
    };

    const requestDeleteUser = async () => {
        const url = `https://admin-vrraces.pavlosmelas.gr/api/user/delete`;

        try {
            const response = await axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${userInfo.access_token}`
                }
            });
            console.log("OK RESPONSE: ", response);
            deleteUserInfo();
            window.location.href = '/login?refresh=true';

        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = async () => {
        await requestDeleteUser();
        deleteUserInfo();
        navigate('/');
    };

    const handleLogout = async () => {
        await deleteUserInfo();
        navigate('/login');
    };

    const onChangeDate = (date: Dayjs | null, dateString: string) => {
        setDateOfBirth(dateString);
        console.log(Number(true), Number(false));

    };


    useEffect(() => {
        console.log(window.localStorage.getItem('userInfo'));

        window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
    }, [userInfo]);

    function logDayNumber(date: Date) {
        return date.getDay();
    }

    dayjs.locale('el');

    const columns: ColumnsType<DataType> = [
        {
            title: 'Ημερ.',
            dataIndex: 'date',
            width: 60,
        },
        {
            title: 'Χρόνος',
            dataIndex: 'completion_time', // Update the dataIndex to match the property name in DataType
            width: 70,
        },
        {
            title: 'Θέση',
            dataIndex: 'place', // Update the dataIndex to match the property name in DataType
            width: 20,
        },
        {
            title: 'Αγώνας',
            dataIndex: 'title',
            width: 210,
        },
    ];



    const onFinish = async () => {
        try {
            const url = `https://admin-vrraces.pavlosmelas.gr/api/user/profile/update`;

            const updatedUserInfo = {
                ...userInfo,
                name: name,
                surname: surname,
                club: club,
                itra: itra,
                gender: gender,
                email: email,
                residence: residence,
                date_of_birth: dateOfBirth, // Use the dateOfBirth state value
                place_of_birth: placeOfBirth,
                club_is_public: club_is_public,
                email_is_public: email_is_public,
                gender_is_public: gender_is_public,
                itra_is_public: itra_is_public,
                name_is_public: name_is_public,
                place_of_birth_is_public: place_of_birth_is_public,
                surname_is_public: surname_is_public,
                date_of_birth_is_public: date_of_birth_is_public,
                residence_is_public: residence_is_public
            };

            const headers = {
                Accept: 'application/json',
                Authorization: `Bearer ${userInfo.access_token}`, // Include the access token in the headers
            };

            const response = await axios.patch(url, updatedUserInfo, { headers });
            console.log('Response:', response.data);
            console.log('DATAAA: ', updatedUserInfo);

            setUserInfo(updatedUserInfo);
            setShowModalUpdate(true);


        } catch (error) {
            console.log(error);

            if (axios.isAxiosError(error)) {
                if (error.response) {
                    setErrorMessage(error.response.data.message);
                }
            }
        }
    };

    const onFinishChange = async (values: any) => {
        try {
            await requestChangePass(values);
            // Clear the error messages on successful form submission
        } catch (error) {
            // Handle error and set the error messages if necessary
        }
        console.log("NEW PASS ERROR STATE: ", errorMessage.old_password[0]);
    };

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        onChangeDate(date, dateString);
    };

    const customRowRenderer = (record: any, index: any) => {
        return {
            onMouseEnter: (event: any) => {
                // Handle row hover event if needed
            },
            onMouseLeave: (event: any) => {
                // Handle row leave event if needed
            },
            className: classes.disableRowHover, // CSS class name for disabling row hover
        };
    };
    const handleCancel = () => {
        setShowModal(false);
    };

    const handleConfirm = () => {
        setShowModal(false);
        handleDelete()
    };

    const getCompletedRaces = async () => {

        try {
            const response = await axios.get(
                `https://admin-vrraces.pavlosmelas.gr/api/user/completed/races`,
                {
                    headers: {
                        Authorization: `Bearer ${userInfo.access_token}`,
                    },
                }
            );


            // get for every race start_date, title
            // and race.result get completion_time, place
            const races = response.data.races;
            const formattedData = races.map((race: any) => ({
                date: race.start_date.split(' ')[0],
                completion_time: race.results[0].completion_time,
                place: race.results[0].place,
                title: race.title,
            }));

            setData(formattedData);


        } catch (error) {
            console.error(error);
        }
    };
    // const data: DataType[] = [];
    // for (let i = 1; i < 100; i++) {
    //     const d = new Date();
    //     data.push({
    //         date: '12/06/2022',
    //         completion_time: 17.23,
    //         place: i,
    //         title: `London, Park Lane no`,

    //         time: '24:34:51',
    //     });
    // }
    useEffect(() => {
        getCompletedRaces();
    }, []);

    return (
        <>
            <HelmetComponent title='Search' descrition='Αναζήτηση' />
            <Modal
                className={classes.confirmModal}
                open={showModalUpdate}
                title=""
                onCancel={() => { setShowModalUpdate(false) }}
                onOk={() => { setShowModalUpdate(false) }}
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="ΟΚ"
            >
                <p>Ο Λογαριασμός σας ανανεώθηκε επιτυχώς!</p>
            </Modal>
            <div className={classes.container}>
                <div className={classes.textBlack}>
                    {t(`ΛΟΓΑΡΙΑΣΜΟΣ ΧΡΗΣΤΗ`)}
                </div>
                <div className={classes.info} style={{ marginTop: 34 }}>

                    <Row justify="center">

                        <div className={classes.results} style={{ marginTop: 34, marginBottom: 43 }}>

                            <Form
                                name="change_account"
                                className="login-form"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                            >
                                <Row>
                                    <Col xl={18} lg={18} md={18} xs={18}>
                                        <div className={classes.detailTitle}>
                                            <span>ΣΤΟΙΧΕΙΑ</span>
                                        </div>
                                    </Col>
                                    <Col xl={3} lg={3} md={3} xs={3}>
                                        <div className={classes.detailTitle}>
                                            <span>ΑΛΛΑΓΗ</span>

                                        </div>
                                    </Col>
                                    <Col xl={3} lg={3} md={3} xs={3}>
                                        <div className={classes.detailTitle}>
                                            <span>ΔΗΜΟΣΙΟ</span>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xl={22}>
                                        <Form.Item
                                            className={classes.row}
                                            name="name"
                                            label="Όνομα"
                                        >
                                            {/* <Input className={classes.inputForm} placeholder="" /><img src={edit} alt="" />
                                             */}
                                            <div className={classes.Data}>
                                                <InfoInput setVal={handleNameChange} defaultValue={t(userInfo.name)} placeholder={t(userInfo.name)}></InfoInput>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={2} className={classes.publicCol}>
                                        <Checkbox className={classes.checkbox} checked={name_is_public} onChange={() => { setName_is_public(!name_is_public) }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={22}>
                                        <Form.Item
                                            className={classes.row}
                                            name="surname"
                                            label="Επίθετο"
                                        >
                                            {/* <Input className={classes.inputForm} placeholder="" /><img src={edit} alt="" />
                                             */}
                                            <div className={classes.Data}>
                                                <InfoInput setVal={handleSurnameChange} defaultValue={t(userInfo.surname)} placeholder={t(userInfo.surname)}></InfoInput>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={2} className={classes.publicCol}>
                                        <Checkbox className={classes.checkbox} checked={surname_is_public} onChange={() => { setSurame_is_public(!surname_is_public) }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={22}>
                                        <Form.Item
                                            className={classes.row}
                                            name="club"
                                            label="Σύλλογος"
                                        >
                                            {/* <Input className={classes.inputForm} placeholder="" /><img src={edit} alt="" />
                                             */}
                                            <div className={classes.Data}>
                                                <InfoInput setVal={handleClubChange} defaultValue={t(userInfo.club)} placeholder={t(userInfo.club)}></InfoInput>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={2} className={classes.publicCol}>
                                        <Checkbox className={classes.checkbox} checked={club_is_public} onChange={() => { setClub_is_public(!club_is_public) }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={22}>
                                        <Form.Item
                                            className={classes.row}
                                            name="itra"
                                            label="Κινητό Τηλ."
                                        >
                                            {/* <Input className={classes.inputForm} placeholder="" /><img src={edit} alt="" />
                                             */}
                                            <div className={classes.Data}>
                                                <div className={classes.Data}>
                                                    <InfoInput
                                                        setVal={handleItraChange}
                                                        defaultValue={userInfo.itra}
                                                        placeholder={userInfo.itra}
                                                    />
                                                </div>

                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={2} className={classes.publicCol}>
                                        <Checkbox className={classes.checkbox} checked={itra_is_public} onChange={() => { setItra_is_public(!itra_is_public) }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={22}>
                                        <Form.Item
                                            className={classes.row}
                                            name="gender"
                                            label="Φύλο"
                                        >
                                            {/* <Input className={classes.inputForm} placeholder="" /><img src={edit} alt="" />
                                             */}
                                            <div className={classes.Data}>
                                                <InfoSelect setVal={handleGenderChange} defaultValue={userInfo.gender} placeholder={userInfo.gender}></InfoSelect>
                                                {/* <InfoInput setVal={setTelephone} defaultValue={t(userInfo.gender)} placeholder={t(userInfo.gender)}></InfoInput> */}
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={2} className={classes.publicCol}>
                                        <Checkbox className={classes.checkbox} checked={gender_is_public} onChange={() => { setGender_is_public(!gender_is_public) }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={22}>
                                        <Form.Item
                                            className={classes.row}
                                            name="email"
                                            label="Email"
                                        >
                                            {/* <Input className={classes.inputForm} placeholder="" /><img src={edit} alt="" />
                                             */}
                                            <div className={classes.Data}>
                                                <InfoInput setVal={handleEmailChange} defaultValue={t(userInfo.email)} placeholder={t(userInfo.email)}></InfoInput>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={2} className={classes.publicCol}>
                                        <Checkbox className={classes.checkbox} checked={email_is_public} onChange={() => { setEmail_is_public(!email_is_public) }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={22}>
                                        <Form.Item
                                            className={classes.row}
                                            name="residence"
                                            label="Τπ. Διαμονής"
                                        >
                                            {/* <Input className={classes.inputForm} placeholder="" /><img src={edit} alt="" />
                                             */}
                                            <div className={classes.Data}>
                                                <InfoInput setVal={handleResidenceChange} defaultValue={t(userInfo.residence)} placeholder={t(userInfo.residence)}></InfoInput>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={2} className={classes.publicCol}>
                                        <Checkbox className={classes.checkbox} checked={residence_is_public} onChange={() => { setResidence_is_public(!residence_is_public) }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={22}>
                                        <Form.Item
                                            className={classes.row}
                                            name="date_of_birth"
                                            label="Ημ. Γέννησης"
                                        >
                                            {/* <Input className={classes.inputForm} placeholder="" /><img src={edit} alt="" />
                                             */}
                                            <div className={classes.Data}>
                                                <InfoDate setVal={setDateOfBirth} defaultValue={t(userInfo.date_of_birth)} placeholder={t(userInfo.date_of_birth)} ></InfoDate>

                                                {/* <DatePicker onChange={onChangeDate} locale={locale} className={classes.datePicker} placeholder="" cellRender={undefined} /> */}
                                                {/* <DatePicker
                                                    className={classes.genderSelect}
                                                    defaultValue={dayjs(userInfo.date_of_birth)}
                                                    placeholder={userInfo.date_of_birth}></DatePicker> */}
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={2} className={classes.publicCol}>
                                        <Checkbox className={classes.checkbox} checked={date_of_birth_is_public} onChange={() => { setDate_of_birth_is_public(!date_of_birth_is_public) }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={22}>
                                        <Form.Item
                                            className={classes.row}
                                            name="place_of_birth"
                                            label="Τπ. Γέννησης"
                                        >
                                            {/* <Input className={classes.inputForm} placeholder="" /><img src={edit} alt="" />
                                             */}
                                            <div className={classes.Data}>
                                                {/* <DatePicker onChange={onChange} locale={locale} className={classes.datePicker} placeholder="" /> */}
                                                <InfoInput setVal={handlePlaceOfBirthChange} defaultValue={t(userInfo.place_of_birth)} placeholder={t(userInfo.place_of_birth)}></InfoInput>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={2} className={classes.publicCol}>
                                        <Checkbox className={classes.checkbox} checked={place_of_birth_is_public} onChange={() => { setPlace_of_birth_is_public(!place_of_birth_is_public) }} />
                                    </Col>
                                </Row>
                                <Form.Item>
                                    <br />
                                    <div className={classes.login_container}>
                                        <Button type="primary" htmlType="submit" className={classes.upload_btn}>ΑΝΑΝΕΩΣΗ</Button>
                                    </div>
                                </Form.Item>
                            </Form>


                            <div className={classes.detailTitle}>
                                <span>ΑΛΛΑΓΗ ΣΥΝΘΗΜΑΤΙΚΟΥ</span>
                            </div>

                            <Form
                                name="change_password"
                                className="login-form"
                                initialValues={{ remember: true }}
                                onFinish={onFinishChange}
                            >
                                <div className={classes.title}>
                                    {t('Τωρινό Συνθηματικό')}
                                </div>
                                <Form.Item
                                    name="ch_password0"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Παρακαλούμε εισάγετε το τωρινό συνθηματικό!'
                                        }
                                    ]}

                                >
                                    <Input.Password className={classes.inputForm} />

                                </Form.Item>
                                {errorMessage.old_password && <p style={{ color: 'red' }}>{errorMessage.old_password}</p>}
                                <div className={classes.title}>
                                    {t('Νέο Συνθηματικό')}
                                </div>
                                <Form.Item
                                    name="ch_password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Παρακαλούμε εισάγετε το νέο συνθηματικό σας!'
                                        }
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password className={classes.inputForm} />

                                </Form.Item>
                                {errorMessage.new_password && <p style={{ color: 'red' }}>{errorMessage.new_password}</p>}
                                <div className={classes.title}>
                                    {t('Επιβεβαίωση συνθηματικού')}
                                </div>
                                <Form.Item
                                    name="confirm_ch_password"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Παρακαλούμε επιβεβαιώστε το νέο σας συνθηματικό!',
                                        }
                                    ]}
                                >
                                    <Input.Password className={classes.inputForm} />
                                </Form.Item>
                                <br />

                                <Form.Item>
                                    <div className={classes.login_container}>
                                        <Button type="primary" htmlType="submit" className={classes.upload_btn}>ΑΛΛΑΓΗ</Button>
                                    </div>
                                </Form.Item>
                            </Form>
                            <div className={classes.login_container}>
                                <Button type="primary" htmlType="submit" onClick={handleLogout} className={classes.logout_btn}>ΑΠΟΣΥΝΔΕΣΗ</Button>
                            </div>
                            <div className={classes.login_container} style={{ marginTop: 60 }}>
                                <Button type="primary" onClick={() => { setShowModal(true) }} htmlType="submit" className={classes.delete_btn}>ΟΡΙΣΤΙΚΗ ΔΙΑΓΡΑΦΗ ΛΟΓΑΡΙΑΣΜΟΥ</Button>
                                <Modal
                                    className={classes.confirmModal}
                                    open={showModal}
                                    title="ΠΡΟΣΟΧΗ!"
                                    onCancel={() => { setShowModal(false) }}
                                    onOk={handleDelete}
                                    okText="ΔΙΑΓΡΑΦΗ"
                                    cancelText="ΑΚΥΡΩΣΗ"
                                >
                                    <p>Είστε σίγουροι πως θέλετε να διαγράψετε οριστικά τον λογαρασμό;</p>
                                </Modal>
                            </div>
                        </div>
                    </Row >


                </div >

                <div className={classes.infoTable} style={{ marginBottom: 43 }}>
                    <div className={classes.detailTitle}>
                        <span>ΙΣΤΟΡΙΚΟ ΧΡΟΝΩΝ</span>
                    </div>
                    <Table className={classes.table} columns={columns} dataSource={data} onRow={customRowRenderer} pagination={{ defaultPageSize: 3, showSizeChanger: false }} size="middle" />

                </div>

            </div >
        </>
    );
};

export default Account;