import { Col, Row } from "antd";
import classes from "./css/MobileApps.module.css";
import iphone from "../../../img/iPhone_12_Mockup_1.png";
import googleplay from "../../../img/app-button-google-play.png";
import appstore from "../../../img/app-store-download-button.png";

const MobileApps = () => {
  return (
    <div className={classes.container}>
      <div className={classes.apps_container}>
        <div className={classes.apps}>
          <Row>
            <Col xl={10}>
              <img src={iphone} alt="iphone-mockup" />
            </Col>
            <Col xl={14}>
              <div className={classes.app_title}>
                <span>Δεν έχετε “έξυπνο” ρολόι;</span>
                <br />
                <span>Έχουμε έξυπνη εφαρμογή!</span>
              </div>
              <div className={classes.app_desc}>
                <span>
                  Κατέβασε δωρεάν την εφαρμογή για iOS και Android, ώστε να
                  μπορείς να μοιράζεσαι εύκολα και γρήγορα τα αποτελέσματά σου.
                </span>
              </div>

              <Row className={classes.storeIcons}>
                <Col className={classes.app_btn}>
                  <a
                    href="https://play.google.com/store/apps/details?id=gr.dotsoft.vrraces&pli=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={googleplay} alt="iphone-mockup" />
                  </a>
                </Col>
                <Col className={classes.app_btn}>
                  <a
                    href="https://apps.apple.com/gr/app/vr-races/id6451126963"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={appstore} alt="iphone-mockup" />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MobileApps;
