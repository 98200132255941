import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

.ant-table-tbody> tr:focus,.ant-table-tbody> td:hover, .ant-table-tbody:hover table:hover, ant-table-row ant-table-row-level-0:hover, ant-table-row ant-table-row-level-0{
    background: red !important;
}

.ant-pagination {
    gap: 10px;
}

.ant-pagination-item {
    font-family: 'ZonaPro-SemiBold';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.019em;
    color: #FFFFFF;
    background: #FFF !important;
    border-radius: 0 !important;
    
    
}



.ant-pagination-item a {
    color: #122b52 !important;
}

.ant-pagination-item-active {
    
    background: #122B52 !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 0 !important;
}

.ant-pagination-item-active a {
    color: #FFF !important;
}

.ant-pagination svg {
    color: #FFF;
}

.ant-table:hover {
    background: red !important;
    
}
.ant-table:focus {
    background: red !important;
}
.ant-table-tbody > tr:hover > td {
    background-color: inherit !important;
}

.ant-pagination-item-ellipsis {
    color: #FFF !important;
}

.react-calendar__navigation--prev2-button,
.react-calendar__navigation--prev-button,
.react-calendar__navigation--next2-button,
.react-calendar__navigation--next-button {
  display: none !important;
}
tr {
    background-color: #263C5F !important;
}

.ant-picker-month-panel{
    min-height: 314px;
}
:where(.css-dev-only-do-not-override-1vtf12y).ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #263C5F;
    color: white;
}

.ant-picker-dropdown {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

@media only screen and (max-width: 680px) {
    .ant-picker-month-panel {
        min-height: 338px;
    }
}

`;