import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import classes from "./Search.module.css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Checkbox, Form, notification } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { Link } from "react-router-dom";
import HelmetComponent from "../../components/Helmet/Helmet";
import { LoadingOutlined } from "@ant-design/icons";
import MyCalendar from "./Calendar/Calendar";
import Modal from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import { UserAuthContext } from "../../context/UserAuthContext";
import type { RadioChangeEvent } from "antd";
import { UserAuthType } from "../../types/UserAuth";
import parse from "html-react-parser";
import { Spin } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
interface Distance {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

const Search = () => {
  const { t } = useTranslation();
  const { userInfo } = useContext(UserAuthContext) as UserAuthType;
  const [raceList, setRaceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState<string>("startDate");
  const [sort, setSort] = useState<string>("ASC");
  const { isOpen, toggle } = useModal();
  const { isOpenToggleSuccess, toggleSuccess } = useModal();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [searchTerms, setSearchTerms] = useState<any[]>(
    Number(queryParams.get("distance"))
      ? [Number(queryParams.get("distance"))]
      : []
  );
  const [searchResults, setSearchResults] = useState<any>([]);
  const [distances, setDistances] = useState<Distance[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [participantStatus, setParticipantStatus] = useState<{
    [key: number]: boolean;
  }>({});
  const [month, setMonth] = useState<number>(
    Number(queryParams.get("month"))
      ? Number(queryParams.get("month"))
      : new Date().getMonth() + 1
  );

  const [year, setYear] = useState<number>(
    Number(queryParams.get("year"))
      ? Number(queryParams.get("year"))
      : new Date().getFullYear()
  );

  const [modalId, setModalId] = useState<string>("");
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalStartDate, setModalStartDate] = useState<string>("");
  const [modalEndDate, setModalEndDate] = useState<string>("");

  const getDistances = async () => {
    try {
      const url = "https://admin-vrraces.pavlosmelas.gr/api/distances";
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      setDistances(response.data);
    } catch (error: any) {
      console.error("Error fetching data:", error);
      if (error.response?.data?.message === "Unauthorized user") {
        setErrorMessage("Please log in to search for distances!");
      }
    }
  };

  const getRaces = async () => {
    try {
      const url = `https://admin-vrraces.pavlosmelas.gr/api/races?sort=${sort}&month=${month}&year=${year}`;

      const response = await axios.get(url);

      const filteredRaceList = response.data.filter((race: any) => {
        const endDate = parseDate(race.end_date);
        const currentDate = new Date();
        return endDate > currentDate;
      });

      setRaceList(filteredRaceList);
      setLoading(false);
    } catch (error: any) {
      console.error("Error fetching races:", error);

      if (error && error.response) {
        if (error.response.data.message === "Unauthorized user") {
          setErrorMessage("Please log in to search for races!");
        } else if (
          error.response.data.message === "Μη εξουσιοδοτημένος χρήστης"
        ) {
          setErrorMessage(
            "Παρακαλούμε συνδεθείτε/εγγραφείτε για να αναζητήσετε αγώνες!"
          );
        } else {
          setErrorMessage("An error occurred. Please try again later.");
        }
      }
    }
  };

  const parseDate = (dateString: string) => {
    const parts = dateString.split(" ");
    const dateParts = parts[0].split("/");
    const timeParts = parts[1].split(":");
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const year = parseInt(dateParts[2], 10);
    const hour = parseInt(timeParts[0], 10);
    const minute = parseInt(timeParts[1], 10);
    const second = parseInt(timeParts[2], 10);

    return new Date(year, month, day, hour, minute, second);
  };

  const isParticipant = async (raceId: number): Promise<boolean> => {
    try {
      const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
      if (!userInfo || !userInfo.id) {
        return false;
      }

      const url = `https://admin-vrraces.pavlosmelas.gr/api/race/participants?race_id=${raceId}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      const filteredRace = response.data.find(
        (item: any) => item.id === raceId
      );

      if (filteredRace) {
        const participant = filteredRace.users.find(
          (participant: any) => participant.id === userInfo.id
        );

        if (participant) {
          return true;
        } else {
          console.log("Participant not found");
          return false;
        }
      } else {
        console.log(`Race with ID ${raceId} not found in participant data`);
      }
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
    return false;
  };

  function hasDatePassed(start_date: string): boolean {
    const formattedDate = moment(start_date, "DD/MM/YYYY HH:mm:ss");
    const currentDate = moment();

    return currentDate.isAfter(formattedDate);
  }

  const handleSelectMonth = (selectedMonth: number) => {
    setMonth(selectedMonth);
  };

  const handleSelectYear = (selectedYear: number) => {
    setYear(selectedYear);
  };

  const participate = (
    id: string,
    title: string,
    start_date: string,
    end_date: string
  ) => {
    toggle();
    setModalId(id);
    setModalTitle(title);
    setModalStartDate(start_date);
    setModalEndDate(end_date);
  };

  const onFinish = async () => {
    try {
      const url = `https://admin-vrraces.pavlosmelas.gr/api/user/race/participate`;

      const data = {
        race_id: modalId,
      };

      const headers = {
        Authorization: `Bearer ${userInfo.access_token}`,
      };

      const response = await axios.post(url, data, { headers });
      console.log("Response:", response.data);

      toggle();
      openNotification("top");
      setParticipantStatus((prevStatus) => ({
        ...prevStatus,
        [modalId]: true,
      }));
    } catch (error) {
      console.log(error);

      if (axios.isAxiosError(error)) {
        if (error.response) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
      }
    }
  };

  const openNotification = (placement: NotificationPlacement | undefined) => {
    notification.open({
      message: <span style={{ fontFamily: "Black" }}>{modalTitle}</span>,
      description: (
        <span style={{ color: "#23C552" }}>
          Εγγραφήκατε επιτυχώς στον αγώνα!
        </span>
      ),
      placement,
      duration: 0,
    });
  };

  useEffect(() => {
    getDistances();
    getRaces();
  }, [searchTerms, sort, month, year, value]);

  useEffect(() => {
    const fetchParticipantStatus = async () => {
      const statusMap: { [key: number]: boolean } = {};
      for (const race of searchResults) {
        const isParticipantResult = await isParticipant(race.id);
        statusMap[race.id] = isParticipantResult;
      }
      setParticipantStatus(statusMap);
    };

    fetchParticipantStatus();
  }, [searchResults]);

  useEffect(() => {
    let results: any = [];
    searchTerms.forEach((searchTerm) => {
      const filteredResults = raceList.filter((item: any) => {
        return item.distance_id === searchTerm;
      });
      results.push(...filteredResults);
    });

    setSearchResults(results);
    if (searchTerms.length === 0) {
      setSearchResults(raceList);
    }
  }, [searchTerms, sort, month, raceList]);

  if (loading && !errorMessage) {
    return (
      <div className={classes.spinContainer}>
        <Spin indicator={antIcon} />
      </div>
    );
  }

  const onChangeDistance = (checkedValues: CheckboxValueType[]) => {
    setSearchTerms(checkedValues);
  };

  return (
    <>
      <HelmetComponent
        title="Βρες έναν Αγώνα - VR RACES"
        descrition="Βρες έναν Αγώνα"
      />
      <Modal isOpen={isOpen} toggle={toggle}>
        <div className={classes.overlay_header}>
          <button className={classes.cross} onClick={toggle}>
            x
          </button>
          <div
            className={classes.modalTitle}
            style={{ textAlign: "center", fontSize: 16 }}
          >
            Δήλωση Συμμετοχής
          </div>
          <div
            className={classes.title}
            style={{ textAlign: "center", fontSize: 27 }}
          >
            {modalTitle}
          </div>
          <div
            className={classes.dates}
            style={{ textAlign: "center", fontSize: 18 }}
          >
            {modalStartDate} - {modalEndDate}
          </div>
          <div
            className={classes.results}
            style={{ marginTop: 34, marginBottom: 43 }}
          >
            {userInfo.access_token ? (
              <Form
                name="normal_register"
                className={classes.loginForm}
                initialValues={{ remember: false }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(new Error("Υποχρεωτικό!")),
                    },
                  ]}
                >
                  <Checkbox className={classes.checkbox}>
                    <u>
                      Συμμετέχω στους εικονικούς αγώνες δρόμου με δική μου
                      ελεύθερη βούληση και φέρω την πλήρη ευθύνη της συμμετοχής
                      μου όσον αφορά τη φυσική μου κατάσταση και την ικανότητά
                      μου να συμμετέχω. Οι διοργανωτές δεν φέρουν καμία ευθύνη
                      σε περίπτωση τραυματισμού, ζημίας ή άλλου είδους φθοράς
                      που μπορεί να υποστώ συμμετέχοντας στη διοργάνωση, κατά τη
                      διάρκεια του αγώνα ή μετά το τέλος του.
                    </u>
                  </Checkbox>
                </Form.Item>
                <br />
                <Form.Item
                  name="approves_result_sharing"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(new Error("Υποχρεωτικό!")),
                    },
                  ]}
                >
                  <Checkbox className={classes.checkbox}>
                    Συμφωνώ με τους όρους εγγραφής και επεξεργασίας προσωπικών
                    δεδομένων όπως αυτοί περιγράφονται στους{" "}
                    <a
                      href="/terms-of-use"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <u>ΟΡΟΥΣ ΧΡΗΣΗΣ</u>
                    </a>
                  </Checkbox>
                </Form.Item>
                <br />
                <br />

                <span className={classes.requiredTerms}>
                  * Υποχρεωτικά πεδία
                </span>

                <br />

                <Form.Item>
                  <div className={classes.login_container}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={classes.upload_btn}
                    >
                      ΕΠΙΒΕΒΑΙΩΣΗ
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            ) : (
              <div className={classes.login_container}>
                <div
                  className={classes.dates}
                  style={{ textAlign: "center", fontSize: 14 }}
                >
                  Παρακαλούμε συνδεθείτε για να συνεχίσετε!
                </div>
                <Button
                  href="/login"
                  type="primary"
                  htmlType="submit"
                  className={classes.upload_btn}
                >
                  ΕΙΣΟΔΟΣ/ΕΓΓΡΑΦΗ
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal isOpen={isOpenToggleSuccess} toggle={toggleSuccess}>
        <div className={classes.overlay_header}>
          <button className={classes.cross} onClick={toggleSuccess}>
            x
          </button>
          <div
            className={classes.modalTitle}
            style={{ textAlign: "center", fontSize: 16 }}
          >
            Δήλωση Συμμετοχής
          </div>
        </div>
      </Modal>
      <div className={classes.container}>
        <div
          className={classes.info}
          style={{ marginTop: 34, marginBottom: 43 }}
        >
          <div className={classes.textBlack}>
            {t(`ΑΝΑΖΗΤΗΣΗ ΑΓΩΝΩΝ ΔΡΟΜΟΥ`)}
          </div>

          <Row justify="center">
            <Col xs={24} sm={24} md={9} lg={7}>
              <div className={classes.checkBoxesContainer}>
                <p className={classes.filters}>{t("ΦΙΛΤΡΑ & ΤΑΞΙΝΟΜΗΣΗ")}</p>
                {/* <Radio.Group onChange={onChangeRadio} value={value} className={classes.radio_group}>
                                    <Space direction="vertical">
                                        <Radio value={'startDate'}>Ημερ. έναρξης</Radio>
                                        <Radio value={'recent'}>Πιο πρόσφατοι</Radio>
                                    </Space>
                                </Radio.Group> 
                                <div className={classes.line} />*/}
                <Checkbox.Group
                  className={classes.checkboxes_container}
                  options={distances.map((distance) => ({
                    label: distance.name,
                    value: distance.id,
                  }))}
                  defaultValue={[Number(queryParams.get("distance"))]}
                  onChange={onChangeDistance}
                />
                <div className={classes.line} />
                <div className={classes.calendar}>
                  <MyCalendar
                    onSelectMonth={handleSelectMonth}
                    onSelectYear={handleSelectYear}
                    month={month}
                    year={year}
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={15} lg={17}>
              <Row justify="center">
                <div className={classes.cards_grid}>
                  {searchResults.length > 0 ? (
                    searchResults.map((item: any) => (
                      <div key={item.id} className={classes.card}>
                        <img
                          src={`https://admin-vrraces.pavlosmelas.gr/${item.banner[0].url}`}
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            height: 153,
                            objectPosition: "center",
                          }}
                          alt="banner"
                        />
                        <div className={classes.card_info}>
                          <div className={classes.title}>{item.title}</div>
                          <div className={classes.details}>
                            {parse(item.description)}
                          </div>
                          {/* <div className={classes.dates}>{item.start_date} - {item.end_date}</div> */}
                          <div className={classes.dates}>
                            {" "}
                            {moment(item.start_date, "DD/MM/YYYY HH:mm").format(
                              "DD/MM/YYYY HH:mm"
                            )}
                            {" - "}
                            {moment(item.end_date, "DD/MM/YYYY HH:mm").format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </div>

                          {!hasDatePassed(item.end_date) &&
                          !participantStatus[item.id] ? (
                            <button
                              className={classes.join}
                              onClick={() => {
                                participate(
                                  item.id,
                                  item.title,
                                  moment(
                                    item.start_date,
                                    "DD/MM/YYYY HH:mm"
                                  ).format("DD/MM/YYYY HH:mm"),
                                  moment(
                                    item.end_date,
                                    "DD/MM/YYYY HH:mm"
                                  ).format("DD/MM/YYYY HH:mm")
                                );
                              }}
                            >
                              ΔΗΛΩΣΕ ΣΥΜΜΕΤΟΧΗ
                            </button>
                          ) : (
                            <Link to={`/results/${item.id}`}>
                              <button
                                className={classes.join}
                                style={{
                                  display: "flex",
                                  background: "#523012",
                                }}
                                onClick={toggle}
                              >
                                ΑΠΟΤΕΛΕΣΜΑΤΑ
                              </button>
                            </Link>
                          )}
                        </div>
                      </div>
                    ))
                  ) : !errorMessage ? (
                    <div>Δεν βρέθηκαν αποτελέσματα!</div>
                  ) : (
                    <div style={{ display: "flex !important" }}>
                      {errorMessage}
                    </div>
                  )}
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default Search;
