import { useState, useEffect } from "react";
import { EditOutlined, SaveOutlined } from '@ant-design/icons'
import { Checkbox } from 'antd'
import classes from './styles.module.css'
import EditIcon from '../../../img/edit2.png'
import { Row, Col } from 'antd'

type Props = {
  defaultValue: string;
  placeholder: string;
  setVal: any;
};

export const InfoInput: React.FC<Props> = ({ defaultValue, placeholder, setVal }) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [disabled, setDisabled] = useState(true);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (!clicked) {
      setVal(defaultValue);
    } else {
      setVal(inputValue);
    }
  }, [clicked, defaultValue, setVal, inputValue]);

  const handleEditClick = () => {
    setClicked(true);
    setDisabled(false);
  };

  const handleSaveClick = () => {
    setClicked(true);
    setDisabled(true);
    // save logic goes here
  };

  return (
    <Row>
      <label className={classes.label}>
        <input
          type="text"
          className={classes.Data}
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          placeholder={placeholder}
          readOnly={disabled}
        />
        {disabled && (
          <button
            type="button"
            onClick={handleEditClick}
            className={classes.edit}
          >
            <img src={EditIcon} alt="edit" />
          </button>
        )}

        {!disabled && (
          <button
            type="button"
            onClick={handleSaveClick}
            className={classes.edit}
          >
            <Col>
              <img src={EditIcon} alt="edit" />
            </Col>
          </button>
        )}
      </label>
    </Row>
  );
};
