import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { UserAuthType, UserInfo } from '../types/UserAuth';

export const UserAuthContext = createContext<UserAuthType | null>(null);

type Props = {
  children?: ReactNode;
};

export const DefaultUserInfo: UserInfo = {
  id: 0,
  name: '',
  surname: '',
  club: '',
  itra: '',
  gender: '',
  residence: '',
  email: '',
  date_of_birth: '',
  place_of_birth: '',
  access_token: '',
  name_is_public: false,
  surname_is_public: false,
  club_is_public: false,
  itra_is_public: false,
  gender_is_public: false,
  email_is_public: false,
  place_of_birth_is_public: false,
  date_of_birth_is_public: false,
  residence_is_public: false,
};

const UserAuthProvider: FC<Props> = ({ children }) => {
  const storedUserInfo = localStorage.getItem('userInfo');
  const initialUserInfo = storedUserInfo ? JSON.parse(storedUserInfo) : DefaultUserInfo;

  const [userInfo, setUserInfo] = useState<UserInfo>(initialUserInfo);

  useEffect(() => {
    window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }, [userInfo]);

  const getUserInfo = (): UserInfo => {
    return userInfo;
  };

  const saveUserInfo = (newUserInfo: UserInfo) => {
    setUserInfo(newUserInfo);
  };

  const deleteUserInfo = () => {
    setUserInfo(DefaultUserInfo);
    console.log("USER DELETION");

    window.localStorage.removeItem('userInfo');

    // Redirect to the login page with a unique key to force refresh
    // window.location.href = '/login?refresh=true';
  };

  const userAuthContextValue: UserAuthType = {
    userInfo,
    getUserInfo,
    saveUserInfo,
    deleteUserInfo,
  };

  return (
    <UserAuthContext.Provider value={userAuthContextValue}>
      {children}
    </UserAuthContext.Provider>
  );
};

export default UserAuthProvider;
