import React, { useState } from "react";
import classes from "./About.module.css";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "antd";
import HelmetComponent from "../../components/Helmet/Helmet";

const About = () => {
  const { t } = useTranslation();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  return (
    <>
      <HelmetComponent
        title={t("VR-RACES") || undefined}
        descrition={t("VR-RACES") || undefined}
      />

      <div className={classes.container}>
        <div className={classes.info}>
          <div className={classes.textBlack}>{t(`ΠΩΣ ΛΕΙΤΟΥΡΓΕΙ`)}</div>

          <div className={classes.main_title}>
            <span>
              Οι εικονικοί αγώνες (VR Races) λειτουργούν ακρinfoιβώς όπως ένας
              αγώνας δρόμου. Η μεγάλη διαφορά είναι ότι μπορείς να τους τρέξεις
              από οποιαδήποτε τοποθεσία με οποιοδήποτε ρυθμό. Το μόνο που έχεις
              να κάνεις είναι να συμμετάσχεις σε έναν αγώνα και να προσκομίσεις
              αποδείξεις ότι το έκανες.
            </span>
          </div>

          <Row justify="center" className={classes.info_col}>
            <Col xs={24} sm={24} md={5} lg={3}>
              <div className={classes.number}>1</div>
            </Col>
            <Col xs={24} sm={24} md={16} lg={12}>
              <div className={classes.info}>
                <span>
                  Επίλεξε αγώνα. Βρες την απόσταση που σου ταιριάζει, από 5
                  χιλιόμετρα έως έναν πλήρη μαραθώνιο, και πραγματοποίησε
                  εγγραφή στον αγώνα.
                </span>
              </div>
            </Col>
          </Row>
          <Row justify="center" className={classes.info_col}>
            <Col xs={24} sm={24} md={5} lg={3}>
              <div className={classes.number}>2</div>
            </Col>
            <Col xs={24} sm={24} md={16} lg={12}>
              <div className={classes.info}>
                <span>
                  Επίλεξε τοποθεσία: Σε αντίθεση με τους παραδοσιακούς αγώνες,
                  οι εικονικοί αγώνες μπορούν να ολοκληρωθούν από οπουδήποτε.
                  Μπορείς να τρέξεις/περπατήσεις σε εξωτερικούς ή εσωτερικούς
                  χώρους, σε διάδρομο ή σε πάρκο - οπουδήποτε θέλεις!
                </span>
              </div>
            </Col>
          </Row>
          <Row justify="center" className={classes.info_col}>
            <Col xs={24} sm={24} md={5} lg={3}>
              <div className={classes.number}>3</div>
            </Col>
            <Col xs={24} sm={24} md={16} lg={12}>
              <div className={classes.info}>
                <span>
                  Ολοκλήρωσε τον αγώνα: Χρησιμοποιώντας την εφαρμογή VR Races
                  στο smartphone σου, ολοκλήρωσε την απόσταση του αγώνα. Η
                  συσκευή πρέπει να έχει ενεργοποιημένη τη δυνατότητα GPS, για
                  να μπορεί να παρακολουθεί την απόσταση και τον χρόνο σου.
                </span>
              </div>
            </Col>
          </Row>
          <Row justify="center" className={classes.info_col}>
            <Col xs={24} sm={24} md={5} lg={3}>
              <div className={classes.number}>4</div>
            </Col>
            <Col xs={24} sm={24} md={16} lg={12}>
              <div className={classes.info}>
                <span>
                  Υπόβαλε το αποτέλεσμά σου: Μετά την ολοκλήρωση του αγώνα, θα
                  πρέπει να υποβάλεις το αποτέλεσμά σου, για να ανέβει στην
                  πλατφόρμα. Αυτό γίνεται συνήθως μέσω της εφαρμογής VR Races.
                  Διαφορετικά, μπορείς να μεταφορτώσεις (ανεβάσεις) τo αρχείο
                  της διαδρομής (GPX) από κάποια άλλη συσκευή (π.χ. smartwatch)
                  ή εφαρμογή (π.χ. Strava).
                </span>
              </div>
            </Col>
          </Row>
          <Row justify="center" className={classes.info_col}>
            <div className={classes.login_container}>
              <Button
                href="/login"
                type="primary"
                htmlType="submit"
                className={classes.btn}
              >
                ΚΑΝΕ ΕΓΓΡΑΦΗ ΓΙΑ ΝΑ ΛΑΒΕΙΣ ΜΕΡΟΣ!
              </Button>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default About;
